<template>
  <div>
    <validation-observer ref="soalForm">
      <b-form @submit.prevent="submitForm" @reset="onReset">
        <b-row class="justify-content-between mb-4 align-items-end">
          <b-col cols="auto">
            <a
              @click="$router.back()"
              href="#"
              class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
            >
              <i class="mdi mdi-chevron-left"></i>
              Kembali
            </a>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between mb-5 mr-1">
          <h3 class="text-dark font-weight-bold d-block">
            Kategori SOAL {{ vendorId }}
          </h3>
          <button
            type="submit"
            class="btn btn-success font-weight-bolder font-size-sm"
          >
            Simpan
          </button>
        </div>
        <div class="d-flex justify-content-start mb-5 mr-1">
          <div
            @click="changeSelected(item)"
            :class="`bg-white border col mx-5 rounded-lg ${
              questionCategories.value == item.value
                ? `border-primary border-2`
                : ''
            }`"
            role="button"
            v-for="item in listTypeOfQuestion"
            :key="item.value"
          >
            <div class="text-center mt-2">
              <h3
                :class="`${
                  questionCategories.value != item.value
                    ? 'text-muted'
                    : 'text-black'
                } fs-5`"
              >
                {{ item.text }}
              </h3>
            </div>
          </div>
        </div>
        <b-row>
          <b-col>
            <div class="card card-custom">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">
                    {{ idSoal ? "Ubah" : "Buat" }} Soal
                  </span>
                </h3>
                <div class="card-toolbar">
                  <span
                    @click="modalBankSoal = true"
                    class="btn btn-warning font-weight-bolder font-size-sm"
                    v-if="idUjian"
                  >
                    Pilih dari Bank Soal
                  </span>
                </div>
              </div>
              <div class="card-body pt-4">
                <b-form-group
                  id="input-group-1"
                  label="Topik soal:"
                  label-for="input-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Topik Soal"
                    rules="required"
                  >
                    <vue-select
                      :state="errors.length > 0 ? false : null"
                      taggable
                      multiple
                      push-tags
                      v-model="currentSoal.topic"
                      :options="topikOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Soal:"
                  label-for="input-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Soal"
                    rules="required"
                  >
                    <editor
                      v-bind:type="`question`"
                      :key="`soal${keySoal}`"
                      v-if="!idSoal || currentSoal.question"
                      v-model="currentSoal.question"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :id="`input-bobot-${i}`"
                  label="Bobot soal:"
                  label-for="input-1"
                  v-if="type == 'essay'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`Bobot Jawaban ${i}`"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="input-1"
                      v-model="currentSoal.point"
                      type="number"
                      placeholder="Masukkan bobot soal"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="align-items-start">
                  <b-col>
                    <b-form-group
                      id="input-group-1"
                      label="Tipe soal:"
                      label-for="input-1"
                      v-if="selected == 'default'"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipe Soal"
                        rules="required"
                      >
                        <b-form-radio-group
                          :state="errors.length > 0 ? false : null"
                          @input="changeType($event)"
                          required
                          stacked
                          :checked="type"
                          :options="[
                            { text: 'Pilihan Ganda', value: 'multiple choice' },
                            { text: 'Jawaban Ganda', value: 'multiple answer' },
                            { text: 'True/False', value: 'bool' },
                            { text: 'Essay', value: 'essay' },
                          ]"
                          value-field="value"
                          text-field="text"
                          name="radio-type"
                        >
                        </b-form-radio-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col v-if="visibleQuestion">
            <div class="card card-custom">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">
                    Pilihan Jawaban
                  </span>
                </h3>
              </div>
              <div class="card-body pt-4">
                <b-form-group
                  :label="`Pilihan ${i + 1}:`"
                  label-for="input-1"
                  v-for="(jawaban, i) in jawabans"
                  :key="`${i}`"
                >
                  <b-row>
                    <b-col
                      cols="auto"
                      class="d-flex flex-column justify-content-between"
                      v-if="selected == 'default'"
                    >
                      <label class="checkbox checkbox-lg checkbox-single">
                        <input
                          :key="`${i}${jawaban.isCorrect}`"
                          type="checkbox"
                          v-model="jawaban.isCorrect"
                          @input="changeJawaban(jawaban)"
                        />
                        <span></span>
                      </label>
                      <a
                        :class="`btn btn-icon btn-light-danger btn-sm`"
                        v-if="currentSoal.type != 'bool' && i != 0"
                        @click="deleteJawaban(jawaban, i)"
                      >
                        <span :class="`svg-icon`">
                          <!--begin::Svg Icon-->
                          <inline-svg
                            src="/media/svg/icons/General/Trash.svg"
                          ></inline-svg>
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                    </b-col>
                    <b-col>
                      <b-form-group
                        :id="`input-bobot-${i}`"
                        label="Bobot jawaban:"
                        label-for="input-1"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`Bobot Jawaban ${i}`"
                        >
                          <b-form-input
                            :state="errors.length > 0 ? false : null"
                            id="input-1"
                            v-model="jawaban.point"
                            type="number"
                            placeholder="Masukkan bobot jawaban"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <template v-if="currentSoal.type != 'bool'">
                        <validation-provider
                          #default="{ errors }"
                          :name="`Pilihan ${i + 1}`"
                          rules="required"
                        >
                          <editor
                            :ref="`answer${i}`"
                            v-bind:type="`question`"
                            :key="`jawaban-${i}`"
                            v-model="jawaban.answer"
                            v-if="questionCategories.type != 'ices'"
                          />
                          <textarea
                            class="form-control"
                            v-else
                            rows="2"
                            disabled
                            v-model="jawaban.answer"
                          ></textarea>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </template>
                      <template v-else>
                        <span
                          :class="{
                            'bg-success': jawaban.answer == 'True',
                            'bg-danger': jawaban.answer == 'False',
                          }"
                          class="badge text-white"
                        >
                          <i
                            :class="{
                              'fa-check': jawaban.answer == 'True',
                              'fa-close': jawaban.answer == 'False',
                            }"
                            class="fa text-white"
                            style="font-size: 10px"
                          ></i>

                          {{ jawaban.answer }}</span
                        >
                      </template>
                      <!-- <div v-else v-html="jawaban.answer"></div> -->
                      <!-- {{ jawaban.answer }} -->
                    </b-col>
                  </b-row>
                </b-form-group>
                <span
                  @click="jawabans.push({})"
                  class="btn btn-primary font-weight-bolder font-size-sm"
                  v-if="currentSoal.type != 'bool' && selected == 'default'"
                >
                  Tambah Jawaban</span
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_SOAL,
  CREATE_SOAL,
  GET_ONE_SOAL,
  SET_ONE_SOAL,
  UPDATE_SOAL,
  GET_ONE_QUESTION,
} from "@/core/services/store/soal.module";
import TinyMCE from "@/view/components/TinyMCE";
import {
  CREATE_BANK_SOAL,
  GET_BANK_SOAL,
  GET_ONE_BANK_SOAL,
  UPDATE_BANK_SOAL,
} from "@/core/services/store/banksoal.module";
import Swal from "sweetalert2";
import mixin from "@/core/services/Helpers";

export default {
  name: "SoalForm",
  mixins: [mixin],
  components: {
    editor: TinyMCE,
  },
  data() {
    return {
      typeOfQuestion: {
        ices: "ices",
        default: "default",
      },
      listTypeOfQuestion: [
        {
          value: "value",
          text: "Value",
          type: "ices",
        },
        {
          value: "sop&sk",
          text: "SOP & SK",
          type: "default",
        },
        {
          value: "others",
          text: "Others",
          type: "default",
        },
      ],
      selected: "ices",
      questionCategories: {
        value: "value",
        text: "Value",
        type: "ices",
      },
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      currentSoal: {},
      type: "multiple choice",
      keySoal: 1,
      isLoading: false,
      isPicked: true,
      jawabanTemp: [],
      modalBankSoal: false,
      bankSoal: [],
      jawabanMultiple: [
        {
          point: 0,
          answer: "",
          isCorrect: true,
        },
      ],
      jawabanPG: [
        {
          answer: "",
          point: 0,
          isCorrect: true,
        },
      ],
      jawabanbool: [
        {
          answer: "True",
          point: 0,
          isCorrect: true,
        },
        {
          answer: "False",
          point: 0,
          isCorrect: false,
        },
      ],
      jawabans: [
        {
          point: 0,
          answer: "",
          isCorrect: false,
        },
        {
          point: 0,
          answer: "",
          isCorrect: false,
        },
        {
          point: 0,
          answer: "",
          isCorrect: false,
        },
        {
          point: 0,
          answer: "",
          isCorrect: false,
        },
      ],
      topikOptions: [],
      currentPage: 1,
      currentPageBank: 1,
      totalRows: 0,
      totalRowsBank: 0,
      perPage: 10,
      perPageBank: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      filterBank: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_soal",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot Soal",
          sortable: true,
        },
        // {
        //   key: 'soal',
        //   label: "Soal",
        //   sortable: true
        // },
        {
          key: "tipe",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      fieldsBank: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_soal",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "soal",
          label: "Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        bool: "True/False",
        essay: "Essay",
      },
      defaultIAnswerCES: [
        {
          point: 0,
          answer: "Integrity",
          isCorrect: false,
        },
        {
          point: 0,
          answer: "Customer Satisfaction",
          isCorrect: false,
        },
        {
          point: 0,
          answer: "Excellence",
          isCorrect: false,
        },
        {
          point: 0,
          answer: "Spirituality",
          isCorrect: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    apiKey() {
      return process.env.VUE_APP_TINY_MCE_API_KEY;
    },
    apiBaseURL() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    // ...mapGetters(["currentSoal"]),
    idSoal() {
      return this.$route.params.idSoal;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
    visibleQuestion() {
      return (
        this.selected == "ices" ||
        (this.selected == "default" && this.type && this.type != "essay")
      );
    },
  },
  watch: {
    jawabans: {
      handler: function (newVal) {
        newVal.forEach((el, i) => {
          var point = parseInt(el.point, 10);
          if (point > 0) {
            this.$set(this.jawabans[i], "isCorrect", true);
          } else {
            this.$set(this.jawabans[i], "isCorrect", false);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch(CLEAR_SOAL);

    if (this.idSoal) {
      this.getData();
    } else {
      this.jawabans = this.defaultIAnswerCES;
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.idSoal ? "Ubah" : "Buat"} Soal` },
    ]);
  },
  methods: {
    deleteJawaban(jawaban, i) {
      if (this.currentSoal.type == "multiple choice") {
        if (jawaban.isCorrect) {
          this.$set(this.jawabans[0], "isCorrect", true);
        }
      }
      this.$delete(this.jawabans, i);
      for (var j = 0; j < this.jawabans.length; j++) {
        this.$set(this.jawabans[j], "key", this.jawabans[j].key + 1);
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    pilihBankSoal(item) {
      this.currentSoal = item;
      if (this.currentSoal.type == "multiple choice") {
        this.jawabanPG = this.currentSoal.jawabans;
        this.jawabans = item.jawabans;
      } else if (this.currentSoal.type == "multiple answer") {
        this.jawabanMultiple = this.currentSoal.jawabans;
        this.jawabans = item.jawabans;
      } else if (this.currentSoal.type == "bool") {
        this.jawabanbool = this.currentSoal.jawabans;
        this.jawabans = item.jawabans;
      } else if (this.currentSoal.type == "essay") {
        this.jawabans = [];
      }
      this.type = item.tipe;
      // this.jawabans = item.jawabans
      setTimeout(() => {
        for (var i = 0; i < this.jawabans.length; i++) {
          // if (this.jawabans[i].id === this.currentSoal.id_jawaban) {
          //   this.$set(this.jawabans[i], 'isCorrect', true)
          // } else {
          //   this.$set(this.jawabans[i], 'isCorrect', false)
          // }
          this.$set(this.jawabans[i], "key", 0);
          delete this.jawabans[i].id;
        }
        this.keySoal += 1;
        delete this.currentSoal.id;
        delete this.currentSoal.kode_soal;
        delete this.currentSoal.id_jawaban;
        this.modalBankSoal = false;
      }, 1);
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_QUESTION, this.idSoal)
        .then((response) => {
          this.currentSoal = response.data;
          setTimeout(() => {
            if (
              this.currentSoal.exam_type == "ICES" ||
              this.currentSoal.exam_type == "ices"
            ) {
              this.selected = this.typeOfQuestion.ices;
            } else {
              this.selected = this.typeOfQuestion.default;
            }

            if (this.currentSoal.exam_categories) {
              this.questionCategories = this.listTypeOfQuestion.filter(
                (x) => x.value == this.currentSoal.exam_categories
              )[0];
            }

            if (this.currentSoal.type == "multiple choice") {
              this.jawabanPG = this.currentSoal.answer;
              this.jawabans = response.data.answer;
            } else if (this.currentSoal.type == "multiple answer") {
              this.jawabanMultiple = this.currentSoal.answer;
              this.jawabans = response.data.answer;
            } else if (this.currentSoal.type == "bool") {
              this.jawabanbool = this.currentSoal.answer;
              this.jawabans = this.currentSoal.answer;
            } else if (this.currentSoal.type == "essay") {
              if (this.currentSoal.answer.length > 0) {
                this.currentSoal.point = this.currentSoal.answer[0].point;
              }
              this.jawabans = [];
            }
            for (var i = 0; i < this.jawabans.length; i++) {
              this.$set(this.jawabans[i], "key", 0);
            }
            this.type = response.data.type;
            // this.jawabans = response.data.jawabans
            this.currentSoal.soal = response.data.question;
          }, 1);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getBankSoal() {
      const param = new URLSearchParams();
      param.append("id_kurikulum", this.idKurikulum);
      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then((response) => {
          this.bankSoal = response.data;
          this.totalRows = this.bankSoal.length;
          // this.isLoading = false
        })
        .catch(() => {
          // this.isLoading = false
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    changeJawaban(item) {
      if (this.currentSoal.type != "multiple answer") {
        for (var i = 0; i < this.jawabans.length; i++) {
          if (this.jawabans[i].isCorrect) {
            this.jawabans[i].point = 0;
            // delete this.jawabans[i].point;
          }
          this.$set(this.jawabans[i], "isCorrect", false);
          // this.currentSoal.jawabans[i].isCorrect = false
        }
        item.isCorrect = true;
      }
      this.isPicked = true;
      this.$store.dispatch(SET_ONE_SOAL, this.currentSoal);
    },
    changeType(value) {
      if (this.currentSoal.type == "multiple choice") {
        this.jawabanPG = this.jawabans;
      } else if (this.currentSoal.type == "multiple answer") {
        this.jawabanMultiple = this.jawabans;
      } else if (this.currentSoal.type == "bool") {
        this.jawabanbool = this.jawabans;
      }

      if (value == "multiple choice") {
        this.jawabans = this.jawabanPG;
      } else if (value == "multiple answer") {
        this.jawabans = this.jawabanMultiple;
      } else if (value == "bool") {
        this.jawabans = this.jawabanbool;
      } else if (value == "essay") {
        this.jawabans = [];
      }
      this.$set(this.currentSoal, "type", value);
      this.type = value;
    },
    submitForm() {
      this.$refs.soalForm.validate().then((success) => {
        if (success) {
          this.currentSoal.exam_type =
            this.selected == this.typeOfQuestion.ices ? "ICES" : "Default";

          this.currentSoal.vendor_id = this.vendorId;
          let _data = JSON.parse(JSON.stringify(this.currentSoal));

          _data.answer = this.jawabans;
          _data.id_ujian = this.idUjian;

          if (_data.exam_type == "ICES") {
            this.defaultIAnswerCES.forEach((element, index) => {
              _data.answer[index].answer = element.answer;
            });
          }

          if (this.selected == this.typeOfQuestion.ices)
            _data.type = "multiple answer";
          else _data.type = this.type;

          if (_data.type == "essay") {
            _data.answer = [
              {
                answer: "",
                point: this.currentSoal.point,
                isCorrect: true,
              },
            ];
          }
          if (this.idSoal) {
            _data.id = this.idSoal;
          }

          if (this.questionCategories) {
            _data.exam_categories = this.questionCategories.value;
          }

          this.isLoading = true;
          this.$store
            .dispatch(this.idSoal ? UPDATE_SOAL : CREATE_SOAL, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.idSoal ? "ubah" : "buat"} soal`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });

              this.$router.push(`/bank-soal`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal Membuat Soal`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() {},
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onEditorChange({ quill, html, text }) {
      this.currentUjian.soal = html;
    },
    changeSelected(typeQuestion) {
      let type = typeQuestion.type;

      // this.$refs.answer0;
      // const [methods] = this.$refs.answer0;
      // methods.value = "";
      Swal.fire({
        title: "Anda yakin akan mengubah tipe?",
        showCancelButton: true,
        confirmButtonText: `Ubah ke ${type == "default" ? "SOP&SK" : "Value"}`,
        cancelButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.questionCategories = typeQuestion;

          this.selected = type;

          if (type == this.typeOfQuestion.ices) {
            this.jawabans = this.defaultIAnswerCES;
            this.defaultIAnswerCES.map((x, i) => {
              this.$set(this.jawabans[i], "isCorrect", false);
              this.$set(this.jawabans[i], "anwer", x.answer);
              this.$set(this.jawabans[i], "point", x.point);
            });
          } else {
            this.jawabans = [];
            this.jawabans.push({});
          }

          // this.jawabans.map((x, index) => {
          //   const editor = this.$refs[`answer${index}`][0];
          //   if (editor) {
          //     editor.resetValue();
          //   }
          // });
        }
      });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
